import * as React from 'react';
import Layout from '../../components/Layout';

const AssetIDs = props=>{
    return (
        <Layout>
            This is AssetIDs
        </Layout>
    );
}

export default AssetIDs;
